import React from 'react'

import Layout from 'components/Layout'
import { Container, Button } from 'components/System'

const Page = () => (
  <Layout>
    <div className="py-8 md:py-16">
      <Container>
        <div className="text-center space-y-4">
          <h1 className="h1">404 — Nicht gefunden</h1>
          <p>Diese Seite konnte leider nicht gefunden werden.</p>
          <hr className="border-0 h-4" />
          <Button fullWidth className="md:max-w-xs" to="/">
            Zur Startseite
          </Button>
        </div>
      </Container>
    </div>
  </Layout>
)

export default Page
